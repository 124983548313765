// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-js": () => import("./../../../src/pages/add.js" /* webpackChunkName: "component---src-pages-add-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-workspaces-js": () => import("./../../../src/pages/workspaces.js" /* webpackChunkName: "component---src-pages-workspaces-js" */),
  "component---src-templates-dev-workspace-js": () => import("./../../../src/templates/DevWorkspace.js" /* webpackChunkName: "component---src-templates-dev-workspace-js" */)
}

